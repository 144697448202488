import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

export const BrandCard = (props) => {
  return (
    <SCard>
      <Link to={props.url}>
        <img src={props.img} alt="brand" />
        <span>{props.txt}</span>
      </Link>
    </SCard>
  );
};

const SCard = styled.div`
  a {
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: ${Color.gray100};
    img {
      width: 160px;
      height: 160px;
      object-fit: contain;
      object-position: center;
    }
    span {
      color: ${Color.gray400};
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 1;
      display: none;
    }
  }
`;
