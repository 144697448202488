import Brand01 from "../images/brand_01.png";
import Brand02 from "../images/brand_02.png";
import Brand03 from "../images/brand_03.png";
import Brand04 from "../images/brand_04.png";
import Brand05 from "../images/brand_05.png";
import Brand06 from "../images/brand_06.png";

export const BrandLists = [
  { txt: "brand01", url: "/brand/brand01", img: Brand01 },
  { txt: "brand02", url: "/brand/brand01", img: Brand02 },
  { txt: "brand03", url: "/brand/brand01", img: Brand03 },
  { txt: "brand04", url: "/brand/brand01", img: Brand04 },
  { txt: "brand05", url: "/brand/brand01", img: Brand05 },
  { txt: "brand06", url: "/brand/brand01", img: Brand06 },
];
