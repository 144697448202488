import React from "react";
import styled from "@emotion/styled";
// components
import { SectionHeading } from "../heading/sectionHeading";
import { BrandCard } from "../card/brandCard";
// utils
import { Color } from "../../utils/color";
import { BrandLists } from "../../utils/brandLists";

export const BrandSection = () => {
  return (
    <SSection>
      <SContainer>
        <SectionHeading txt="ブランドから探す" />
        <SWrapper>
          {BrandLists.map(({ txt, url, img }) => {
            return <BrandCard key={txt} txt={txt} url={url} img={img} />;
          })}
        </SWrapper>
      </SContainer>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  border-bottom: 1px solid ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(320px, 100%), 1fr));
  gap: 1rem;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(min(160px, 100%), 1fr));
    gap: 0.5rem;
  }
`;
